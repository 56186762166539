import logo from './logo.svg';
import { useState } from 'react';
import { Container,Row,Col,Button,ListGroup  } from 'react-bootstrap';
import './font.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';


function App() {
  const [AgendaSection,setAgendaSection] = useState(false);
  const [MapSection,setMapSection] = useState(true);
  const _OpenAgenda =()=>{
    setMapSection(true);
    setAgendaSection(false);
  }
  const _OpenMap =()=>{
    setMapSection(false);
    setAgendaSection(true);
  }
  const _OpenGoogleMap = ()=>{
    window.open("https://maps.google.com?q=18.623934498494247,98.86893341849587");
  }
  return (
    <>
    <Container>
      <Row className="justify-content-md-center mt-2">
        <Col col={12} className='text-center'><span className='kanit-regular dcolor' style={{'font-size':'30px'}}>งานทำบุญขึ้นบ้านใหม่</span></Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col col={12} className='text-center mt-2'><span className='kanit-regular dcolor' style={{'font-size':'30px'}}>ครอบครัว ปุกคำนวล</span></Col>
      </Row>
      <Row>
        <Col col={12} className='text-center mt-4'>
        <span className='kanit-regular dcolor'>ณ บ้านเลขที่ 164/4 หมู่ที่ 5 บ้านอุเม็ง</span>
        </Col>
      </Row>
      <Row>
        <Col col={12} className='text-center mt-1'>
        <span className='kanit-regular dcolor'>ตำบลยุหว่า อำเภอสันป่าตอง จังหวัดเชียงใหม่</span>
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col col={12} className='text-center'>
        <span className='kanit-regular dcolor' style={{'font-size':'25px'}}>วันอาทิตย์ ที่ 28 มกราคม 2567</span>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col col={6} className='text-center'>
          <Button variant="primary" style={{'width':'100px'}} onClick={()=>{_OpenAgenda();}}>กำหนดการ</Button>
        </Col>
        <Col col={6} className='text-center'>
          <Button variant="info" className='text-white' style={{'width':'100px'}} onClick={()=>{_OpenMap();}}>แผนที่</Button>
        </Col>
      </Row>

      <section hidden={AgendaSection}>
      <Row className='mt-4'>
          <Col col={12} className='kanit-regular dcolor text-center' style={{'font-size':'20px'}}>
            ข้อมูลกำหนดการ
          </Col>
        </Row>

        <Row className='mt-4 mb-4'>
          <Col col={12}>
            <ListGroup>
              <ListGroup.Item>
                <Row>
                  <Col xs={4} col={2} className='kanit-light dcolor text-center'>09.09 น.</Col>
                  <Col xs={8} col={10} className='kanit-light dcolor'>พิธีทำบุญขึ้นบ้านใหม่</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col  xs={4} col={2} className='kanit-light dcolor text-center'>11.00 น.</Col>
                  <Col xs={8} col={10} className='kanit-light dcolor'>ถวายภัตตาหารเพล</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col  xs={4} col={2} className='kanit-light dcolor text-center'>11.30 น.</Col>
                  <Col xs={8} col={10} className='kanit-light dcolor'>ขอเชิญรับประทานอาหาร</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </section>
      <section hidden={MapSection}>
        <Row className='mt-4'>
          <Col col={12} className='kanit-regular dcolor text-center' style={{'font-size':'20px'}}>
            ข้อมูลการเดินทาง / แผนที่
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col col={12} className='kanit-light dcolor text-center'>
            <Button variant="info" className='text-white' style={{'width':'200px','height':'50px'}} onClick={()=>{_OpenGoogleMap();}}>เปิด Google Map นำทาง</Button>
          </Col>
        </Row>
        <Row className='mt-5 mb-5'>
          <Col col={12} className='kanit-light dcolor text-center'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d9526.980939523324!2d98.86649786984381!3d18.628740263808655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDM3JzI2LjQiTiA5OMKwNTInMDguMCJF!5e0!3m2!1sth!2sth!4v1705656817940!5m2!1sth!2sth" width="100%" height="450" style={{'border':'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Col>
        </Row>
      </section>
      <Row>
        <Col col={12} className='text-center kanit-regular dcolor mb-3'>
          [ ขออภัยหากมิได้มาเรียนเชิญด้วยตนเอง ]
        </Col>
      </Row>
    </Container>
    </>
    

  );
}

export default App;
